import React from 'react';
import Select from 'react-select';

const renderSavingSpinner = () => (
    <div>
        <div className='spinner'/>
        <button className='btn btn-raised btn-xs btn-primary createNewButton' disabled={true}>
            Create New
        </button>
    </div>
);

const getOptionBackgroundColor = (state, baseBackgroundColor) => {
    if (state.isFocused) {
        return 'rgba(0,126,255,0.08)';
    }
    if (state.isSelected) {
        return 'rgba(0,126,255,0.04)';
    }

    return baseBackgroundColor;
};

export default class SegmentPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingSegmentList: true,
            savingPlaceholder: false,
            fetchError: false,
            selected: null,
        };

        this.selectRef = null;
    }

    componentDidMount() {
        window.tbpFetch(
            this.props.listSegmentUrl,
            { method: 'GET' },
        )
            .then((data) => {
                if (this.props.defaultSegment) {
                    const defaultSegment = data.find((segment) => segment.id === this.props.defaultSegment.id);

                    if (defaultSegment) {
                        this.setState({
                            selected: defaultSegment,
                        });
                    }
                }

                this.setState({
                    segmentList: data,
                    loadingSegmentList: false,
                });
            })
            .catch((error) => {
                if (error.errors.length > 0 && typeof error.errors[0].detail === 'string') {
                    this.setState({
                        fetchError: error.errors[0].detail,
                    });
                }
            });
    }

    handleSelectOnChange = (selected) => {
        if (selected) {
            this.setState({
                savingPlaceholder: false,
                menuOpen: false,
                selected,
            }, () => {
                this.props.onSelectSegment(selected);
            });
        }
    };

    renderNoResults = ({ inputValue }) => {
        if (inputValue && this.props.createSegmentUrl) {
            return this.renderCreateNewSegmentButton(inputValue);
        }

        return 'No results found...';
    };

    renderCreateNewSegmentButton = (attributeName) => {
        if (this.state.savingPlaceholder) {
            return renderSavingSpinner();
        }
        return (
            <button
                className='btn btn-raised btn-xs btn-primary createNewButton'
                onClick={(e) => this.handleCreateNewClick(e, attributeName)}>
                Create New
            </button>
        );
    };

    handleCreateNewClick = (e, segmentName) => {
        e.preventDefault();

        this.setState({
            savingPlaceholder: true,
        }, () => {
            const form = new FormData();
            form.append('form[name]', segmentName);
            form.append('form[_token]', this.props.csrfToken);

            return window.tbpFetch(this.props.createSegmentUrl, {
                method: 'POST',
                body: form,
            })
                .then((data) => {
                    this.state.segmentList.push(data);
                    this.handleSelectOnChange(data);

                    if (this.selectRef) {
                        this.selectRef.blur();
                    }
                })
                .catch((error) => {
                    let errorMessage = '';

                    if (error.errors.length > 0 && typeof error.errors[0].detail === 'string') {
                        errorMessage = error.errors[0].detail;
                    }

                    this.setState({
                        savingPlaceholder: false,
                    }, () => {
                        alert(errorMessage);
                    });
                });
        });
    };

    handlePlaceholderText = () => {
        if (this.state.fetchError !== false) {
            return 'Error fetching segments';
        }

        if (this.state.loadingList) {
            return 'Fetching segments...';
        }

        return 'Select a segment...';
    };

    render() {
        return (
            <Select
                styles={{
                    option: (base, state) => ({
                        ...base,
                        color: '#333',
                        backgroundColor: getOptionBackgroundColor(state, base.backgroundColor),
                    }),
                    noOptionsMessage: (base) => ({
                        ...base,
                        textAlign: 'left',
                        padding: 0,
                    }),
                }}
                name='segmentSelect'
                value={this.state.selected}
                placeholder={this.handlePlaceholderText()}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={this.state.segmentList}
                onChange={this.handleSelectOnChange}
                noOptionsMessage={this.renderNoResults}
                ref={(stateManager) => {
                    if (stateManager?.select) {
                        this.selectRef = stateManager.select;
                    }
                }}
            />
        );
    }
}
