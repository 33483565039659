import { startStimulusApp } from '@symfony/stimulus-bridge';
import AnalyticsController from './js/analytics-controller';
import ModalController from './js/modal-controller';

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./react',
    false,
    /\.[jt]sx?$/,
));
export default app;

app.register('analytics', AnalyticsController);
app.register('modal', ModalController);
