import React, { Component } from 'react';
import update from 'immutability-helper';
import { produce } from 'immer';
import Button from '../../components/Button';
import MessagePicker from '../../components/MessagePicker';
import RadioButton from '../../components/RadioButton';

// TODO: Inject via data attribute
const RANDOM = 20;
const SEQUENTIAL = 21;

function getJsonDOMNode() {
    return document.getElementById('form_payload');
}

function getInitialState() {
    const state = {
        type: RANDOM,
        messages: [],
        messageList: [],
    };

    try {
        const json = getJsonDOMNode().value;
        if (json.length > 0) {
            const newState = JSON.parse(json);

            if (typeof newState === 'object') {
                state.messages = newState.messages;
                state.type = newState.type === 'random' ? RANDOM : SEQUENTIAL;
            }
        }
    } catch (e) {
        // @TODO: Handle this error
        // eslint-disable-next-line no-console
    }

    return state;
}

export default class SequentialRandom extends Component {
    constructor(props) {
        super(props);

        this.state = getInitialState();
        this.state.defaultMessage = {
            messageId: null,
            title: '',
        };
    }

    componentDidMount() {
        this.loadMessages();
    }

    loadMessages = () => {
        window.tbpFetch(
            this.props.listMessageUrl,
            { method: 'GET' },
        ).then((res) => {
            this.setState({
                messageList: res.data.map((m) => ({
                    value: m.id,
                    label: m.title,
                })),
            });
        });
    };

    handlePickerChange = (idx, message) => {
        this.setState((state) => produce(state, (draft) => {
            draft.messages[idx] = {
                messageId: message.id,
                title: message.title,
            };
        }));
    };

    handleDeleteMessage = (idx) => () => {
        this.setState({
            messages: [
                ...this.state.messages.slice(0, idx),
                ...this.state.messages.slice(idx + 1),
            ],
        });
    };

    handleAddMessage = () => {
        this.setState({
            messages: [
                ...this.state.messages,
                this.state.defaultMessage,
            ],
        });
    };

    handleTypeChange = (evt) => {
        this.setState({
            type: Number(evt.currentTarget.value),
        });
    };

    handleMoveMessage = (idx, delta) => {
        const message = this.state.messages[idx];

        this.setState({
            messages: update(this.state.messages, {
                $splice: [
                    [idx, 1],
                    [idx + delta, 0, message],
                ],
            }),
        });
    };

    handleMoveMessageUp = (idx) => () => {
        if (idx > 0) {
            this.handleMoveMessage(idx, -1);
        }
    };

    handleMoveMessageDown = (idx) => () => {
        if (idx < this.state.messages.length - 1) {
            this.handleMoveMessage(idx, 1);
        }
    };

    handleSave = () => {
        getJsonDOMNode().value = JSON.stringify({
            type: this.state.type === RANDOM ? 'random' : 'sequence',
            messages: this.state.messages,
        });
        document.getElementById('message_form').submit();
    };

    renderSortOptions = (idx) => {
        if (this.state.type === RANDOM) {
            return null;
        }

        return (
            <div className='sequentialrandom-builder__movecontrols'>
                <Button
                    className='sequentialrandom-builder__movebutton sequentialrandom-builder__upbutton'
                    onClick={this.handleMoveMessageUp(idx)}
                >
                    <i className='material-icons'>keyboard_arrow_up</i>
                </Button>
                <Button
                    className='sequentialrandom-builder__movebutton sequentialrandom-builder__downbutton'
                    onClick={this.handleMoveMessageDown(idx)}
                >
                    <i className='material-icons'>keyboard_arrow_down</i>
                </Button>
            </div>
        );
    };

    renderMessageListItem = (idx) => {
        const message = this.state.messages[idx];

        return (
            <li className='sequentialrandom-builder__message' key={idx}>
                {this.renderSortOptions(idx)}
                <MessagePicker
                    className='Select--single'
                    listMessageUrl={this.props.listMessageUrl}
                    defaultMessage={message}
                    onSelect={(message) => this.handlePickerChange(idx, message)}
                />
                <Button
                    className='sequentialrandom-builder__deletebutton btn btn-sm btn-raised btn-danger'
                    label='Delete'
                    onClick={this.handleDeleteMessage(idx)}
                />
            </li>
        );
    };

    renderMessageList = () => (
        <ul className='sequentialrandom-builder__messagelist'>
            {this.state.messages.map((_, idx) => this.renderMessageListItem(idx))}
        </ul>
    );

    renderBuilder = () => (
        <div>
            <div className='sequentialrandom-builder__type'>
                <label className='sequentialrandom-builder__typelabel'>Choose responses from your message list:</label>
                <div className='sequentialrandom-builder__typeoptions'>
                    <RadioButton
                        id='type_random'
                        value={RANDOM}
                        checked={this.state.type === RANDOM}
                        onChange={this.handleTypeChange}
                        label='Random'
                    />
                    <RadioButton
                        id='type_sequential'
                        value={SEQUENTIAL}
                        checked={this.state.type === SEQUENTIAL}
                        onChange={this.handleTypeChange}
                        label='In order'
                    />
                </div>
            </div>

            {this.renderMessageList()}

            <Button
                className='sequentialrandom-builder__addbutton btn btn-raised btn-primary'
                label='Add'
                onClick={this.handleAddMessage}
            />

            <hr className='sequentialrandom-builder__ruler'/>

            <Button
                className='btn btn-raised btn-primary'
                label='Save'
                onClick={this.handleSave}
            />
        </div>
    );

    render() {
        return (
            <div className='sequentialrandom-builder'>
                {this.state.messageList.length === 0 ? (
                    <div>Loading...</div>
                ) : this.renderBuilder()}
            </div>
        );
    }
}
