import React, { Component } from 'react';
import * as Popover from '@radix-ui/react-popover';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Cross2Icon } from '@radix-ui/react-icons';

function renderDelay(delay) {
    function getTime(t, l) {
        let res = '';

        const time = t;
        const label = l;
        const multi = (t > 1) ? 's' : '';

        if (t > 0) {
            res = `${time} ${label}${multi} `;
        }

        return <span className="bold">{res}</span>;
    }

    return (
        <p className="PopoverText">
            {getTime(delay.weeks, 'week')}
            {getTime(delay.days, 'day')}
            {getTime(delay.hours, 'hour')}
            {getTime(delay.minutes, 'minute')}
        </p>
    );
}

export default class Preview extends Component {
    constructor() {
        super();
        this.state = {
            MessageUrl: null,
            sequenceID: null,
            sequenceParts: {},
            showPopover: true,
        };
    }

    renderDaysSelected() {
        const days = [
            [1, 'Mon'],
            [2, 'Tue'],
            [3, 'Wed'],
            [4, 'Thu'],
            [5, 'Fri'],
            [6, 'Sat'],
            [0, 'Sun'],
        ];

        const selectedDays = this.props.sequenceDaysToSend[this.props.sequenceID];
        const selectedDaysSorted = days.filter((x) => selectedDays.includes(x[0])).map((x) => x[1]).join(', ');

        return (
            <div className="sequence-preview-days-selected-container">
                <div className="bold">Days Selected</div>
                <div>{selectedDaysSorted}</div>
            </div>
        );
    }

    renderRows() {
        return Object.keys(
            this.props.sequenceParts[this.props.sequenceID],
        ).map((e, i) => (
            <div key={i}>
                { renderDelay(JSON.parse(this.props.sequenceParts[this.props.sequenceID][e].delay)) }
                <a
                    className="Link"
                    href={`${this.props.MessageUrl}/${this.props.sequenceParts[this.props.sequenceID][e].message_id}`}
                >{this.props.sequenceParts[this.props.sequenceID][e].message}</a>
            </div>
        ));
    }

    canRender() {
        if (this.props.sequenceParts[this.props.sequenceID] === undefined) {
            return false;
        }

        return true;
    }

    render() {
        if (!this.canRender()) {
            return null;
        }

        return (
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button className="btn btn-raised btn-sm btn-primary" id="sequence-preview-trigger" tabIndex="0" role="button" aria-label="Update dimensions">
                        Preview
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content className="PopoverContent" sideOffset={5}>
                        <ScrollArea.Root className="ScrollAreaRoot" type={'auto'}>
                            <ScrollArea.Viewport className="ScrollAreaViewport">
                                {this.renderDaysSelected()}
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                    {this.renderRows()}
                                </div>
                            </ScrollArea.Viewport>
                            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
                                <ScrollArea.Thumb className="ScrollAreaThumb" />
                            </ScrollArea.Scrollbar>
                            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
                                <ScrollArea.Thumb className="ScrollAreaThumb" />
                            </ScrollArea.Scrollbar>
                            <ScrollArea.Corner className="ScrollAreaCorner" />
                        </ScrollArea.Root>
                        <Popover.Close className="PopoverClose" aria-label="Close">
                            <Cross2Icon/>
                        </Popover.Close>
                        <Popover.Arrow className="PopoverArrow"/>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    }
}
