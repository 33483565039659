import * as React from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export default class DayPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultValue: this.props.defaultValue,
            onValueChange: this.props.onValueChange,
        };
    }

    render() {
        return (
            <ToggleGroup.Root
                className="DayPicker"
                type="multiple"
                aria-label="Days of the week"
                defaultValue={this.props.defaultValue}
                onValueChange={this.props.onValueChange}
            >
                <ToggleGroup.Item className="DayPickerItem" value={1} aria-label="Monday">
                    Mon
                </ToggleGroup.Item>
                <ToggleGroup.Item className="DayPickerItem" value={2} aria-label="Tuesday">
                    Tue
                </ToggleGroup.Item>
                <ToggleGroup.Item className="DayPickerItem" value={3} aria-label="Wednesday">
                    Wed
                </ToggleGroup.Item>
                <ToggleGroup.Item className="DayPickerItem" value={4} aria-label="Thursday">
                    Thu
                </ToggleGroup.Item>
                <ToggleGroup.Item className="DayPickerItem" value={5} aria-label="Friday">
                    Fri
                </ToggleGroup.Item>
                <ToggleGroup.Item className="DayPickerItem" value={6} aria-label="Saturday">
                    Sat
                </ToggleGroup.Item>
                <ToggleGroup.Item className="DayPickerItem" value={0} aria-label="Sunday">
                    Sun
                </ToggleGroup.Item>
            </ToggleGroup.Root>
        );
    }
}
