import { Controller } from '@hotwired/stimulus';
import { getComponent } from '@symfony/ux-live-component';

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(this.element);

        const url = JSON.parse(document.getElementById('mercureSetupContainer').textContent);
        const eventSource = new EventSource(url);

        eventSource.onmessage = (event) => {
            // Throttle this so it only happens once every 4 seconds, but also delays it 4 seconds to allow the data to update
            this.throttle(() => setTimeout(() => this.component.emit('updateDates'), 5000), 5000)();
        };
    }

    throttle(cb, delay) {
        let wait = false;

        return (...args) => {
            if (wait) {
                return;
            }

            cb(...args);
            wait = true;
            setTimeout(() => {
                wait = false;
            }, delay);
        };
    }
}
