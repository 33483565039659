import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { setNonce } from 'get-nonce';

export default class DelayPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            delayConfig: this.props.delayConfig,
            showError: false,
        };
    }

    componentDidMount() {
        setNonce(this.props.nonce);
    }

    handleInputChange = (event) => {
        const { value } = event.target;
        const { id } = event.target;

        this.setState({
            delayConfig: {
                ...this.state.delayConfig,
                [id]: parseInt(value, 10),
            },
        });
    };

    handleFormSubmit = (event) => {
        event.preventDefault();

        const delayConfigValues = Object.values(this.state.delayConfig);
        const delayConfigValuesSum = delayConfigValues.reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);

        if (delayConfigValuesSum < 1) {
            return this.setState({
                showError: true,
            });
        }

        this.props.onDelayChange(this.state.delayConfig);
        return this.onOpenChange(false);
    };

    renderError = () => {
        if (this.state.showError) {
            return (
                <div className="error">
                    Please set a delay of at least 1 minute.
                </div>
            );
        }

        return null;
    };

    onOpenChange = (open) => {
        this.setState({ open });
    };

    render() {
        return (
            <Dialog.Root open={this.state.open} onOpenChange={this.onOpenChange}>
                <Dialog.Trigger asChild>
                    <span className="btn btn-xs btn-info btn-raised" onClick={() => {
                        this.setState({
                            open: true,
                        });
                    }}>Edit</span>
                </Dialog.Trigger>
                <Dialog.Portal>
                    <Dialog.Overlay className="delayPickerOverlay" />
                    <Dialog.Content className="delayPickerContent">
                        <form onSubmit={this.handleFormSubmit}>
                            <Dialog.Title className="delayPickerTitle">Edit delay</Dialog.Title>
                            <Dialog.Description className="delayPickerDescription">
                                Edit your delay here. Click save when you&apos;re done.
                            </Dialog.Description>
                            <fieldset>
                                <label className="Label" htmlFor="weeks">Weeks</label>
                                <input type="number" defaultValue={this.props.delayConfig.weeks} min="0" id="weeks" onChange={this.handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <label className="Label" htmlFor="days">Days</label>
                                <input type="number" defaultValue={this.props.delayConfig.days} min="0" max="7" id="days" onChange={this.handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <label className="Label" htmlFor="hours">Hours</label>
                                <input type="number" defaultValue={this.props.delayConfig.hours} min="0" max="24" id="hours" onChange={this.handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <label className="Label" htmlFor="minutes">Minutes</label>
                                <input type="number" defaultValue={this.props.delayConfig.minutes} min="0" max="60" id="minutes" onChange={this.handleInputChange} />
                            </fieldset>

                            <div className="delayPickerFooter">
                                {this.renderError()}
                                <button type="submit" className="btn-primary btn-raised btn btn-sm">Save changes</button>
                            </div>
                        </form>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        );
    }
}
