import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

function getDelayText(previewPart) {
    return Object.entries(previewPart.delay).filter(([, value]) => value > 0).map(
        ([key, value]) => (value === 1 ? `${value} ${key.substr(0, key.length - 1)}` : `${value} ${key}`),
    ).join(', ');
}

function offsetDate(date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}

export default class PreviewTimeline extends Component {
    constructor(props) {
        super(props);

        /**
         * Maintain a preview display date so it does not jump around all over the place
         * We use the offset to nullify the local timezone set by browser so whatever time
         * is sent will be equivilent to the browser time.
         */

        const previewDisplayDate = new Date();
        this.state = {
            previewDisplayDate,
            previewDate: offsetDate(previewDisplayDate),
            previewList: [],
            loadingPreviewList: false,
            fetchError: false,
        };
    }

    componentDidMount() {
        if (this.props.sequenceParts.filter((part) => !part.completed).length > 0) {
            return false;
        }

        return this.generateSequencePreview();
    }

    componentDidUpdate(prevProps) {
        if (this.isSequencePartComplete() === false) {
            return false;
        }

        if (prevProps.segment !== this.props.segment) {
            return this.generateSequencePreview();
        }

        if (prevProps.daysToSend !== this.props.daysToSend) {
            return this.generateSequencePreview();
        }

        if (prevProps.sequenceParts !== this.props.sequenceParts) {
            return this.generateSequencePreview();
        }

        return false;
    }

    isSequencePartComplete() {
        if (this.props.sequenceParts.length === 0) {
            return false;
        }

        if (this.props.sequenceParts.filter((part) => !part.completed).length > 0) {
            return false;
        }

        return true;
    }

    generateSequencePreview() {
        this.setState({
            loadingPreviewList: true,
        });

        window.tbpFetch(this.props.previewSequenceUrl, {
            method: 'POST',
            body: JSON.stringify({
                sequenceParts: this.props.sequenceParts,
                daysToSend: this.props.daysToSend,
                SendAt: this.state.previewDate,
            }),
        })
            .then((data) => {
                this.setState({
                    previewList: data.data,
                    loadingPreviewList: false,
                    fetchError: false,
                });
            })
            .catch((error) => {
                if (error.error) {
                    this.setState({
                        loadingPreviewList: false,
                        fetchError: error.error,
                    });
                }
            });
    }

    renderMessageTitle(message) {
        return (
            <a
                className="Link"
                href={`${this.props.messageUrl}/${message.id}`}
            ><strong>{message.title}</strong></a>
        );
    }

    renderSequencePreviewMessages() {
        if (this.state.loadingPreviewList === true) {
            return (
                <div className='preview-loading'>
                    <span>Generating Preview...</span>
                </div>
            );
        }

        if (this.state.fetchError !== false) {
            return (
                <div className='preview-error'>
                    <span>{this.state.fetchError}</span>
                </div>
            );
        }

        if (this.state.previewList.length === 0) {
            return false;
        }

        return this.state.previewList.map((previewPart, index) => (
            <div key={index}>
                <div>
                    <span>{getDelayText(previewPart)}</span> - <span>{previewPart.send_at}</span>
                </div>
                {this.renderMessageTitle(previewPart.message)}
            </div>
        ));
    }

    render() {
        return (
            <>
                <div>
                    <div>
                        <div className='preview-title'>Preview timeline example</div>
                        <span>Select a day a user might enter the segment <strong>{this.props.segment.name}</strong> to see a preview of when those message would be sent.</span>
                    </div>

                    <div className='preview-calender form-group'>
                        <DatePicker
                            className="form-control"
                            selected={this.state.previewDisplayDate}
                            onChange={(date) => {
                                this.setState({
                                    previewDate: offsetDate(date),
                                    previewDisplayDate: date,
                                }, () => {
                                    if (this.isSequencePartComplete() === false) {
                                        return false;
                                    }

                                    return this.generateSequencePreview();
                                });
                            }}
                            showTimeSelect
                            dateFormat="EEEE dd MMMM yyyy hh:mmaa"
                        />
                    </div>

                    <div className='preview-timeline'>
                        {this.renderSequencePreviewMessages()}
                    </div>
                </div>
            </>
        );
    }
}
