import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    modal = null;

    connect() {
        const modalId = this.element.attributes.modalid.value;
        this.modal = document.getElementById(modalId);

        document.addEventListener('modal:close', (event) => {
            if (modalId === event.detail.id) {
                this.modal.close();
            }
        });
    }
}
