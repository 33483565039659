import React, { Component } from 'react';

export default class RadioButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <label className='radiobutton__label' htmlFor={this.props.id}>
                <input {...this.props}
                    id={this.props.id}
                    type='radio'
                    value={this.props.value}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                /> {this.props.label}
            </label>
        );
    }
}
