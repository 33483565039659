/* global third_party_disable, sentry_project, version, user_id */

// require bower modules as npm modules
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import 'moment-timezone';
import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-rowreorder';
import '../libraries/bootstrap-material-design/js/index';
import 'flatpickr';
import 'jquery.ns-autogrow';
import 'jquery-emoji-picker/js/jquery.emojipicker';
import 'jquery-emoji-picker/js/jquery.emojis';
import 'select2/dist/js/select2.full.min';
import 'jquery-validation';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

// import bower css files
import 'select2/dist/css/select2.min.css';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
import 'jquery-emoji-picker/css/jquery.emojipicker.css';
import Clipboard from 'clipboard';

window.moment = require('moment');
window.Sortable = require('../../node_modules/sortablejs/Sortable');

const $ = require('jquery');
global.$ = global.jQuery = $;

$(document).ready(function () {
    var clipboard = new Clipboard('.clipboard');
    clipboard.on('success', function (e) {
        $(e.trigger).children('.tooltip-text').css('display', 'initial');

        setTimeout(function () {
            $(e.trigger).children('.tooltip-text').css('display', 'none');
        }, 1500);

        e.clearSelection();
    });
});

window.emojiPickerDefaults = {
    height: '300px',
    width: '450px',
    iconBackgroundColor: 'transparent',
};

window.tbpFetch = (path, options) => new Promise((resolve, reject) => {
    const fetchOptions = {
        ...options,
        headers: {
            ...typeof options.headers === 'object' && {
                ...options.headers,
            },
            'X-Requested-With': 'XMLHttpRequest',
        },
    };

    return fetch(path, fetchOptions)
        .then((response) => {
            if (!response.ok) {
                return response.text()
                    .then((text) => {
                        if (response.status === 403 && text === 'session_timeout') {
                            return window.dispatchEvent(new CustomEvent('tbp:sessiontimeout'));
                        }

                        try {
                            return reject(JSON.parse(text));
                        } catch (e) {
                            return reject(text);
                        }
                    })
                    .catch(console.error);
            }

            if (response.headers.get('Content-Type') === 'text/html; charset=UTF-8') {
                return resolve(response.text());
            }

            return resolve(response.json());
        })
        .catch(console.error);
});

if (third_party_disable === false && sentry_dsn && sentry_dsn.length > 0) {
    Sentry.init({
        dsn: sentry_dsn,

        // To set your release version
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        release: `${sentry_project}@${version}`,

        allowUrls: ['thebotplatform.com', 'cloudfront.net'],
    });

    if (user_id !== false) {
        Sentry.setUser({ id: user_id });
    }
}

// IE11 Polyfill for `new Event()`;
((function () {
    if (typeof window.CustomEvent === 'function') {
        return false;
    }

    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
})());

const autogrow = function () {
    $('textarea').not('.added-growth').each(function () {
        $(this).addClass('added-growth');
        $(this).autogrow({ vertical: true, horizontal: false });
    });
};

const resetUI = function () {
    $('select').each(function () {
        if ($(this).parents('.date-time-container').length === 0) {
            $(this).select2();
        }
    });

    $.material.init();

    autogrow();

    $(window).on('tbp:autogrow', (_evt) => {
        autogrow();
    });

    $('.left-icon').click(() => {
        $('.left').toggleClass('active');
    });

    $('.date-picker-button').each(function () {
        $(this).hide();
    });
    if ($('#keyword_keyword') && $('#keyword_keyword').length > 0) {
        $('#keyword_keyword').emojiPicker(emojiPickerDefaults);
    }
};

const applyConfirmationPrompts = function () {
    $(document).on('click', '.confirmbox', (evt) => {
        if (!confirm('Are you sure?')) {
            evt.preventDefault();
        }
    });
};

const toggleNewCategory = function () {
    const categoryForm = $('#newCategoryForm');
    const errors = $('.has-error').length;

    if (errors > 0) {
        categoryForm.css('display', 'block');
    }

    $('.newCategoryButton').click((e) => {
        e.preventDefault();

        const categoryFormDisplayState = categoryForm.css('display');
        if (errors > 0) {
            categoryForm.css('display', 'block');
        } else if (categoryFormDisplayState === 'none') {
            categoryForm.css('display', 'block');
        } else {
            categoryForm.css('display', 'none');
        }
    });
};

$(() => {
    $(window).on('tbp:sessiontimeout', () => {
        window.onbeforeunload = null;
        if (confirm('Your login session has expired, you will be prompted to login')) {
            window.location.replace(login_url);
        }
    });

    resetUI();
    applyConfirmationPrompts();
    toggleNewCategory();
});
