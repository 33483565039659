import React, { Component } from 'react';

export default class Button extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <button {...this.props}
                type='button'
                onClick={this.props.onClick}
            >
                {this.props.label}
                {this.props.children}
            </button>
        );
    }
}
