import React from 'react';

export default class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                value: this.props.value,
            });
        }
    }

    onChange(value) {
        this.setState({
            value,
        });

        this.props.onChange(value);
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor={this.props.id} className="control-label required">{this.props.label}</label>
                <input autoFocus={this.props.autofocus === true} type="text" id={this.props.id} value={this.state.value} onChange={(e) => this.onChange(e.target.value)} name={this.props.name} required="required" placeholder={this.props.placeholder} className="form-control form-control" />
                <span className="help-block"></span>
            </div>
        );
    }
}
