import React, { Component } from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import { NonceProvider } from 'react-select';

import SegmentPicker from '../../components/SegmentPicker';
import MessagePicker from '../../components/MessagePicker';
import DelayPicker from '../../components/DelayPicker';
import DayPicker from '../../components/DayPicker';
import TextInput from '../../components/TextInput';
import PreviewTimeline from '../../components/PreviewTimeline';

function getDelayText(sequencePart) {
    return Object.entries(sequencePart.delay).filter(([, value]) => value > 0).map(
        ([key, value]) => (value === 1 ? `${value} ${key.substr(0, key.length - 1)}` : `${value} ${key}`),
    ).join(', ');
}
export default class Sequences extends Component {
    constructor() {
        super();

        this.state = {
            showSegmentPicker: true,
            name: '',
            selectedSegment: null,
            daysToSend: '[]',
            listSequenceParts: [],
            localSequenceParts: [],
            defaultDelayConfig: {
                weeks: 0,
                days: 0,
                hours: 0,
                minutes: 0,
            },
            messageUrl: null,
        };
    }

    componentDidMount() {
        if (this.props.segment) {
            this.setState({
                showSegmentPicker: false,
                selectedSegment: JSON.parse(this.props.segment),
            });
        }

        if (this.props.sequence_parts) {
            const listSequenceParts = JSON.parse(this.props.sequence_parts).map((sequencePart) => ({
                ...sequencePart,
                completed: true,
                edit: false,
            }));

            this.setState({
                listSequenceParts,
            });
        }

        if (this.props.daysToSend) {
            this.setState({
                daysToSend: this.props.daysToSend,
            });
        }

        if (this.props.title) {
            this.setState({
                name: this.props.title,
            });
        }
    }

    addSequencePart = () => {
        this.setState({
            listSequenceParts: [
                ...this.state.listSequenceParts,
                {
                    delay: this.state.defaultDelayConfig,
                    message: null,
                    completed: false,
                    edit: false,
                    errors: [],
                },
            ],
        }, window.pageChanged);
    };

    updateLocalSequencePart = (index, sequencePart) => {
        const getLocalSequencePartIndex = this.state.localSequenceParts.findIndex((part) => part.id === index);

        if (getLocalSequencePartIndex === -1) {
            return this.setState({
                localSequenceParts: [
                    ...this.state.localSequenceParts,
                    {
                        ...sequencePart,
                        id: index,
                    },
                ],
            });
        }

        return this.setState({
            localSequenceParts: [
                ...this.state.localSequenceParts.slice(0, getLocalSequencePartIndex),
                {
                    ...sequencePart,
                    id: index,
                },
                ...this.state.localSequenceParts.slice(getLocalSequencePartIndex + 1),
            ],
        });
    };

    removeLocalSequencePart = (index) => {
        const getLocalSequencePartIndex = this.state.localSequenceParts.findIndex((part) => part.id === index);

        if (getLocalSequencePartIndex !== -1) {
            this.setState({
                localSequenceParts: [
                    ...this.state.localSequenceParts.slice(0, getLocalSequencePartIndex),
                    ...this.state.localSequenceParts.slice(getLocalSequencePartIndex + 1),
                ],
            });
        }
    };

    cancelSequencePartChange = (index, sequencePart) => {
        if (sequencePart.delay === this.state.defaultDelayConfig && sequencePart.message === null) {
            return this.removeSequencePart(index);
        }

        this.removeLocalSequencePart(index);

        return this.setState({
            listSequenceParts: [
                ...this.state.listSequenceParts.slice(0, index),
                sequencePart,
                ...this.state.listSequenceParts.slice(index + 1),
            ],
        });
    };

    updateSequencePart = (index, sequencePart) => {
        const delayConfigValues = Object.values(sequencePart.delay);
        const delayConfigValuesSum = delayConfigValues.reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);

        const part = sequencePart;
        part.completed = delayConfigValuesSum > 0 && part.message !== null;

        part.errors = [];
        if (!part.completed) {
            if (delayConfigValuesSum <= 0) {
                part.errors.push('Please select a delay of at least 1 minute.');
            }

            if (part.message === null) {
                part.errors.push('Please select a message.');
            }
        }

        if (part.edit === true) {
            part.completed = false;
        }

        this.setState({
            listSequenceParts: [
                ...this.state.listSequenceParts.slice(0, index),
                part,
                ...this.state.listSequenceParts.slice(index + 1),
            ],
        });

        this.removeLocalSequencePart(index);

        if (part.completed === true) {
            window.pageChanged();
        }
    };

    removeSequencePart = (index) => {
        this.removeLocalSequencePart(index);

        this.setState({
            listSequenceParts: [
                ...this.state.listSequenceParts.slice(0, index),
                ...this.state.listSequenceParts.slice(index + 1),
            ],
        }, window.pageChanged);
    };

    updateDaysToSend = (days) => {
        this.setState({
            daysToSend: `[${days.join(',')}]`,
        }, window.pageChanged);
    };

    renderAddSequencePartButton() {
        if (this.state.selectedSegment === null) {
            return null;
        }

        if (this.state.listSequenceParts.length > 0) {
            const lastSequencePart = this.state.listSequenceParts[this.state.listSequenceParts.length - 1];
            if (lastSequencePart.completed === false) {
                return null;
            }
        }

        return (
            <div className="sequence-step-add-button">
                <div onClick={this.addSequencePart}>
                    <PlusIcon />
                </div>
            </div>
        );
    }

    renderMessageTitle(message) {
        return (
            <a
                className="Link"
                href={`${this.props.messageUrl}/${message.id}`}
            ><strong>{message.title}</strong></a>
        );
    }

    getDaysToSendDefault() {
        let daysToSend = '[]';

        if (typeof this.props.daysToSend !== 'undefined') {
            daysToSend = JSON.parse(this.props.daysToSend);
        }

        return daysToSend;
    }

    renderSequenceDayPicker() {
        return (
            <div>
                <span>Messages in this sequence could be sent on the following days:</span>
                <br />
                <DayPicker
                    defaultValue={this.getDaysToSendDefault()}
                    onValueChange={(days) => {
                        if (days) this.updateDaysToSend(days);
                    }} />
            </div>
        );
    }

    renderSequenceParts() {
        if (this.state.selectedSegment === null) {
            return null;
        }

        return this.state.listSequenceParts.map((sequencePart, index) => {
            const firstOrNext = index === 0 ? 'first' : 'next';
            const localSequencePart = this.state.localSequenceParts.find((part) => part.id === index) || sequencePart;

            return (
                <div className={`sequence-step-container ${sequencePart.completed === true ? 'sequence-step-completed' : ''}`} key={index} data-testid={`step-${index + 1}`}>
                    <div className="sequence-step-header">Step {index + 1}</div>
                    <div className="sequence-step-body">
                        {
                            sequencePart.completed === true ? (
                                <div className="sequence-step-segment-selected">
                                    <div className="sequence-step-segment-edit"></div>
                                    <div className="sequence-step-segment-text">
                                        <span>{getDelayText(sequencePart)}</span> - {this.renderMessageTitle(sequencePart.message)}
                                    </div>
                                    <div className="sequence-step-segment-edit">
                                        <button className="btn btn-xs btn-info btn-raised" type="button" onClick={() => {
                                            this.updateSequencePart(index, {
                                                ...sequencePart,
                                                edit: true,
                                                id: index,
                                            });
                                        }}>Edit</button>
                                        <button className="btn btn-xs btn-danger btn-raised" type="button" onClick={() => {
                                            if (window.confirm('Are you sure you want to remove this step? It will be permanently deleted.')) {
                                                this.removeSequencePart(index);
                                            }
                                        }}>Delete</button>
                                    </div>
                                </div>
                            ) : (
                                <div className="sequence-edit-flex">
                                    <div className="sequence-step-text">
                                        Click &quot;Edit&quot; to choose your {firstOrNext} delay and choose your {firstOrNext} message from the message dropdown menu.
                                    </div>
                                    <div className="sequence-step-delay-label">
                                        <strong>Delay</strong>: {getDelayText(localSequencePart)} <DelayPicker
                                            nonce={this.props.nonce}
                                            delayConfig={localSequencePart.delay}
                                            onDelayChange={(delayConfig) => {
                                                this.updateLocalSequencePart(index, {
                                                    ...localSequencePart,
                                                    delay: delayConfig,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="sequence-step-edit-flex">
                                        <div className="sequence-step-react">
                                            <MessagePicker
                                                csrfToken={this.props.csrfToken}
                                                listMessageUrl={this.props.listMessageUrl}
                                                createMessageUrl={this.props.createMessageUrl}
                                                defaultMessage={sequencePart.message}
                                                onSelect={(message) => {
                                                    this.updateLocalSequencePart(index, {
                                                        ...localSequencePart,
                                                        message,
                                                    });
                                                } } />
                                        </div>
                                        <div className="sequence-step-segment-edit">
                                            <button className="btn btn-xs btn-raised" type="button" onClick={() => {
                                                this.cancelSequencePartChange(index, {
                                                    ...sequencePart,
                                                    edit: false,
                                                    completed: true,
                                                });
                                            }}>Back</button>
                                            <button className="btn btn-xs btn-success btn-raised" type="button" onClick={() => {
                                                this.updateSequencePart(index, {
                                                    ...localSequencePart,
                                                    edit: false,
                                                });
                                            }}>Save changes</button>
                                            <button className="btn btn-xs btn-danger btn-raised" type="button" onClick={() => {
                                                if (window.confirm('Are you sure you want to remove this step? It will be permanently deleted.')) {
                                                    this.removeSequencePart(index);
                                                }
                                            }}>Delete</button>
                                        </div>
                                    </div>
                                    {
                                        sequencePart.errors.map((error, id) => (
                                            <div className="error" key={id}>{error}</div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            );
        });
    }

    renderSegmentPart() {
        return (
            <div className={`sequence-step-container ${this.state.showSegmentPicker === false && this.state.selectedSegment.name ? 'sequence-step-completed' : ''}`}>
                <div className="sequence-step-header">Segment</div>
                <div className="sequence-step-body">
                    {
                        this.state.showSegmentPicker === true ? (
                            <div>
                                <div className="sequence-step-text">
                                    Choose your segment from the dropdown menu. People in this segment will receive this sequence once they&apos;ve opted into the segment and once the sequence is live.
                                </div>
                                <div className="sequence-step-label">
                                    Send to Segment
                                </div>
                                <div className="sequence-step-react">
                                    <SegmentPicker
                                        csrfToken={this.props.csrfToken}
                                        listSegmentUrl={this.props.listSegmentUrl}
                                        createSegmentUrl={this.props.createSegmentUrl}
                                        defaultSegment={this.state.selectedSegment}
                                        onSelectSegment={(segment) => {
                                            this.setState({
                                                selectedSegment: segment,
                                                showSegmentPicker: false,
                                            }, window.pageChanged);

                                            if (this.state.listSequenceParts.length === 0) {
                                                this.addSequencePart();
                                            }
                                        } } />
                                </div>
                            </div>
                        ) : (
                            <div className="sequence-step-segment-selected">
                                <div className="sequence-step-segment-edit"></div>
                                <div className="sequence-step-segment-text">
                                    Send to Segment: <strong>{this.state.selectedSegment.name}</strong>
                                </div>
                                <div className="sequence-step-segment-edit">
                                    <button className="btn btn-xs btn-info btn-raised" type="button" onClick={() => {
                                        this.setState({ showSegmentPicker: true });
                                    }}>Edit</button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

    isValid() {
        if (this.state.selectedSegment === null) {
            return 'Please select a segment ';
        }

        if (this.state.name === '') {
            return 'Please enter a name ';
        }

        if (JSON.parse(this.state.daysToSend).length === 0) {
            return 'Please select days to send ';
        }

        if (this.state.listSequenceParts.length === 0) {
            return 'Please add some steps ';
        }

        for (let i = 0; i < this.state.listSequenceParts.length; i += 1) {
            if (!this.state.listSequenceParts[i].completed) {
                return 'Please complete all the steps in your sequence ';
            }
        }

        return true;
    }

    isPushLiveValid() {
        if (!this.props.botIsLaunched) {
            return 'Please connect your bot';
        }

        return this.isValid();
    }

    renderFormButtons() {
        return (
            <div className="form-group label-floating">
                <div className="form-footer">
                    <a className="btn btn-raised" href={this.props.sequenceIndexUrl}>Back</a>

                    {
                        this.isValid() === true ? (
                            <button id="sequence_save" className="btn btn-raised btn-success" type="submit">Save</button>
                        ) : (
                            <button id="sequence_save" disabled className="btn btn-raised btn-success" type="submit">Save</button>
                        )
                    }

                    {
                        this.isPushLiveValid() === true ? (
                            <button
                                id="showLiveModalBtn"
                                className="btn btn-raised btn-primary"
                            >Push live</button>
                        ) : (
                            <button
                                id="showLiveModalBtn"
                                className="btn btn-raised btn-primary"
                                title={`${this.isPushLiveValid()} to push your sequence live.`}
                                disabled
                            >Push live</button>
                        )
                    }
                </div>
            </div>
        );
    }

    renderSequencePreview() {
        if (this.state.selectedSegment === null) {
            return false;
        }

        return (
            <div className="sequence-preview-timeline-container">
                <PreviewTimeline
                    previewSequenceUrl={this.props.previewSequenceUrl}
                    messageUrl={this.props.messageUrl}
                    segment={this.state.selectedSegment}
                    daysToSend={this.state.daysToSend}
                    sequenceParts={this.state.listSequenceParts}
                />
            </div>
        );
    }

    render() {
        return (
            <NonceProvider nonce={this.props.nonce} cacheKey="react-select">
                <div className="sequence-container">
                    <div className='well sequence-builder-container' data-testid="sequence-builder-container">
                        <TextInput
                            label="Sequence Name"
                            id="sequence_name"
                            name="sequence[title]"
                            placeholder="Name"
                            autofocus={true}
                            value={this.props.title}
                            onChange={(value) => {
                                this.setState({
                                    name: value,
                                }, window.pageChanged);
                            }}
                        />
                        <div className="">
                            <h3>Sequence</h3>
                            <div className="sequence-desc">Use the following widgets to add your delays and messages. Your sequence will begin once you make it live and a user has entered the segment.</div>
                        </div>
                        <div
                            id="sequenceBuilder"
                            data-segment={this.state.selectedSegment ? JSON.stringify(this.state.selectedSegment) : null}
                            data-sequence-parts={this.state.listSequenceParts.length > 0 ? JSON.stringify(this.state.listSequenceParts) : null}
                            data-days-to-send={this.state.daysToSend.length > 0 ? this.state.daysToSend : null}
                        >
                            {this.renderSequenceDayPicker()}
                            {this.renderSegmentPart()}
                            {this.renderSequenceParts()}
                            {this.renderAddSequencePartButton()}
                        </div>
                    </div>

                    {this.renderSequencePreview()}
                </div>

                {this.renderFormButtons()}
            </NonceProvider>
        );
    }
}
